import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import TravelLinkDoc from '~/components/Widget/TravelLinkDoc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TravelLinkDoc mdxType="TravelLinkDoc" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      